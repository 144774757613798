import * as appConstant from '../Constants/appConstants'

export const getColorFromStatus = (value) => {
    let color = '';

    switch (value) {
        case appConstant.ACTIVE:
            color = 'success';
            break;
        case appConstant.INACTIVE:
            color = 'fail';
            break;
        default:
            color = 'var(--primary)';
            break;
    }

    return color;
}

export const getColorOfStatus = (value) => {
    let color = '';

    switch (value) {
        case appConstant.OPEN:
            color = 'open';
            break;
        case appConstant.CANCELLED:
            color = 'cancelled';
            break;
        case appConstant.CONFIRMED:
            color = 'confirmed';
            break;
        case appConstant.COMPLETED:
            color = 'completed';
            break;
        case appConstant.ACCEPTED:
            color = 'accepted';
            break;
        case appConstant.TRIP_ENDED:
            color = 'trip_ended';
            break;
        case appConstant.REACHED:
            color = 'reached';
            break;
        case appConstant.STARTED:
            color = 'started';
            break;
        default:
            color = 'var(--primary)';
            break;
    }

    return color;
}

export function debounceFunction(func, wait) {
    let timeout;
    return function (...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
    };
}
