import { Mixpanel } from './mixpanel.js';
import { getLocalData } from '../Services/common.js';
import { LOGIN, CLIENT_ID } from '../Constants/storageConstant.js';

const trackEvent = (eventName, data) => {
    Mixpanel.track(eventName, data);
};

const analyticsConstant = {
    API_TIME: 'API_TIME',
};

export const KEYS = {
    ID: 'id',
    USER_ROLE: 'User Role',
    USER_NAME: 'User Name',
    CLIENT_ID: 'Client ID',
    APP_ENV: 'App ENV',
    RESPONSE_TIME_MS: 'Response Time (ms)',
    FULL_URL: 'Full URL',
    ORIGIN: 'ORIGIN',
    ENDPOINT: 'ENDPOINT',
    MSG: 'message',
    STATUS: 'Status',
};

const getDefaultParams = () => {
    const userData = getLocalData(LOGIN);
    const clientID = getLocalData(CLIENT_ID);

    return {
        [KEYS.ID]: userData?.id || '-',
        [KEYS.USER_NAME]: userData?.name || '-',
        [KEYS.USER_ROLE]: userData?.role_name || '-',
        [KEYS.CLIENT_ID]: clientID || '-',
        [KEYS.APP_ENV]: process.env.REACT_APP_ENV || '-',
    };
};

export const trackAPITime = (time, url, status) => {
    let baseUrl = url;
    let endPoint = url;

    try {
        const parsedUrl = new URL(url);
        endPoint = parsedUrl.pathname.replace('/api', 'api');
        baseUrl = parsedUrl.origin;
    } catch (error) {
        console.error('Error extracting base URL:', error);
    }

    trackEvent(analyticsConstant.API_TIME, {
        ...getDefaultParams(),
        [KEYS.RESPONSE_TIME_MS]: time,
        [KEYS.STATUS]: status,
        [KEYS.FULL_URL]: url,
        [KEYS.ORIGIN]: baseUrl,
        [KEYS.ENDPOINT]: endPoint,
    });
};
