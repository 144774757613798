import moment from 'moment/moment';

export const changeDateFormatForServer = (date) => {
    return date ? moment(date, "DD-MM-YYYY").format("YYYY-MM-DD") : '';
}
export const showDate = (isoDate) => {
    const date = new Date(isoDate);

    // Extract day, month, and year
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();

    // Format as dd-mm-yyyy
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;

}
export const changeDateFormatForDisplay = (date) => {
    return date ? moment(date, "DD-MM-YYYY").format("DD MMM YYYY") : '';
}

export const changeDateFormatForDisplay2 = (date) => {
    return date ? moment(date, "YYYY-MM-DD").format("DD MMM YYYY") : '';
}

export function tomorrowDate() {
    return moment().add(1, 'days').format("DD/MM/YYYY");
}

export function nowDateTime() {
    return moment().format("DD MMM YYYY hh:mm A");
}

export function nowTime(format) {
    return moment().format(format);
}

export function dateTime(hours = 0, date = moment()) {
    return moment(date).add(hours, 'hours').format("YYYY-MM-DD HH:mm:ss");
}

export function formatAndAddDate(days, format = "DD-MM-YYYY", date = new Date()) {
    return moment(date, format).add(days, 'days')
}

export function momentToDate(date) {
    return moment(date)?.toDate()
}

export function momentYear() {
    return moment().year()
}

export const isValidDate = (date) => {
    return !isNaN(Date.parse(date));
};

export function isBetween(beginTime, startTime, endTime) {
    return moment(beginTime).isBetween(startTime, endTime) || (moment(beginTime).isSame(moment(startTime)))
}

export function momentResult(input) {
    return moment(input)
}

export function timeFormat(time, format = "hh:mm A") {
    return moment(time, "HH:mm").format(format);
}

export function timeFormatForServer(time, format = "HH:mm:ss") {
    return moment(time, "HH:mm").format(format);
}

export function momentDuration(input) {
    return moment.duration(input)
}

export const convertTimeTo12HourFormat = (time) => {
    return time ? moment(time, 'HH:mm').format('h:mm A') : '';
}
