export const BASE_URL = process.env.REACT_APP_BASE_URL + 'api/';
export const IMAGE_BASE_URL = process.env.REACT_APP_BASE_URL + 'storage/';

export const LOGIN = "auth/login";
export const REGISTER = "auth/register";

export const FORGOT_PASSWORD = "auth/forgot-password";
export const RESET_PASSWORD = "auth/reset-password";
export const CHANGE_PASSWORD = "profile/change-password";

export const VERIFY_OTP = "auth/verify";
export const RESEND_OTP = "auth/resend-otp";

export const GET_PROFILE = "profile";
export const UPDATE_PROFILE = "profile/update";

export const MASTER_DATA = "master-data";
export const SYSTEM_DATA = "system-data";

// ------------- user api ------------
export const GET_USERS_LIST = "users";
export const UPSERT_USER = "users/upsert";
export const DELETE_USER = "users/delete/";

// ---------- package api -----------------
export const GET_PACKAGE_LIST = "package";
export const UPSERT_PACKAGE = "package/upsert";
export const DELETE_PACKAGE = "package/delete/";

// ---------- Vehicle api -----------------
export const GET_VEHICLE_LIST = "vehicle";
export const UPSERT_VEHICLE = "vehicle/upsert";
export const DELETE_VEHICLE = "vehicle/delete/";

// ------------- Dispatcher api ------------
export const GET_DISPATCHER_LIST = "dispatcher";
export const UPSERT_DISPATCHER = "dispatcher/upsert";
export const DELETE_DISPATCHER = "dispatcher/delete/";
export const GET_DISPATCHER_DETAILS = "dispatcher/";
export const GET_DISPATCHER_BOOKINGS = "dispatcher/bookings/"
export const GET_PAYMENT_LIST = "dispatcher/payment";
export const UPSERT_PAYMENT = "dispatcher/payment";
// ---------- client api -----------------
export const GET_CLIENT_LIST = "client";
export const UPSERT_CLIENT = "client/upsert";
export const DELETE_CLIENT = "client/delete/";

export const GET_BUSINESS_LIST = "business";
export const UPSERT_BUSINESS = "business/upsert";

export const GET_BANK_LIST = "bank";
export const UPSERT_BANK = "bank/upsert";
export const DELETE_BANK = "bank/delete/";

export const GET_INVOICE_LIST = "invoice";
export const UPSERT_INVOICE = "invoice/upsert";
export const CHANGE_INVOICE_STATUS = "invoice/status/";
export const CHANGE_PACKAGES_STATUS = "package/status/";
export const CHANGE_VEHICLE_STATUS = "vehicle/status/";
export const CHANGE_USER_STATUS = "users/status/";
export const UPSERT_INVOICE_PAYMENT = "invoice-payment/upsert";
export const DELETE_INVOICE = "invoice/delete/";

export const GET_PROFORMA_LIST = "proforma-invoice";
export const GET_DASHBOARD_DATA = "dashboard";
export const GET_PROFORMA_INVOICE = "proforma-invoice/";
export const UPSERT_PROFORMA = "proforma-invoice/upsert";
export const DELETE_PROFORMA_INVOICE = "proforma-invoice/delete/";

export const GET_BOOKING_LIST = "bookings";
export const GET_BOOKING_DETAILS = "bookings/";
export const UPSERT_BOOKING = "bookings/upsert";
export const UPSERT_BOOKING_DETAILS = "bookings/upsert-details";
export const UPSERT_PAYMENT_DETAILS = "bookings/payments/upsert";
export const CANCEL_BOOKING = "bookings/status/";

export const BOOKING_PLACES = "bookings/places";

export const BOOKING_INVOICE = "invoice/booking-invoice/";
export const DELETE_PAYMENT = "dispatcher/payment/";



