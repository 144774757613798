import { useEffect, useState } from "react";
import noIMg from "../../../Assets/img/no_img.jpg"
import MainLoader from "../Loader/MainLoader";

const ImageUploadBox = ({ handleImageUpload, image, convertImage = false }) => {

    const [img, setImg] = useState('');

    useEffect(() => {
        if (convertImage) {
            if (image) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setImg(reader.result);
                };

                reader.readAsDataURL(image);
            }
        } else {
            setImg(image);
        }
    }, [image]);

    return (
        <div
            className="image-upload-box"
            onClick={() => document.getElementById('imageInput').click()}
            style={{
                width: '150px',
                height: img ? "auto" : '150px',
                maxHeight: img ? "160px" : "auto",
                border: '2px dotted #ccc',
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                cursor: 'pointer',
                position: 'relative',
                overflow: 'hidden'
            }}
        >
            <img
                id="previewImage"
                className="position-relative"
                src={img}
                alt="Logo"
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = noIMg;
                }}
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '8px',
                    display: img ? 'block' : 'none'
                }}
            />
            {!img && <p id="uploadText" style={{ margin: 0, color: '#999', fontSize: "0.8rem" }}>Add your Logo Here</p>}
            <input
                type="file"
                id="imageInput"
                accept="image/*"
                onChange={handleImageUpload}
                style={{ display: 'none' }}
            />
        </div>
    );
};

export default ImageUploadBox;
