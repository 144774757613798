import {
  FaLongArrowAltRight,
  FaRegEnvelopeOpen,
  FaInstagram,
  FaFacebookSquare,
  FaArrowAltCircleDown,
  FaListOl,
  FaInfoCircle
} from "react-icons/fa";
import { GoPlus } from "react-icons/go";
import { RiCheckDoubleFill } from "react-icons/ri";
import { IoIosLogOut, IoMdMenu } from "react-icons/io";
import { PiCurrencyInrBold } from "react-icons/pi";
import { IoIosHelpCircleOutline, IoIosArrowDropdown } from "react-icons/io";
import { RxCross2, RxCrossCircled } from "react-icons/rx";
import { FcGoogle } from "react-icons/fc";
import { FiPlusCircle, FiUsers, FiPackage } from "react-icons/fi";
import { HiOutlineDocumentReport } from "react-icons/hi";
import {
  IoLockClosedOutline,
  IoLockOpenOutline,
  IoBagAddOutline,
  IoArrowBackOutline,
  IoSearchOutline,
  IoFilterSharp,
  IoCarSportOutline,
  IoAddCircleOutline,
} from "react-icons/io5";
import { MdPublishedWithChanges } from "react-icons/md";
import { FaAngleLeft } from "react-icons/fa6";
import { AiOutlineUserAdd, AiOutlineDashboard, AiOutlinePercentage, AiOutlineAccountBook } from "react-icons/ai";
import { CiCalendarDate } from "react-icons/ci";
import { MdOutlineShortcut, MdOutlineContentCopy, MdEditNote } from "react-icons/md";
import { GoTrash } from "react-icons/go";
import { BiPurchaseTag } from "react-icons/bi";
import Loader from "react-spinners/ClipLoader";
import { MdBusinessCenter, MdManageAccounts } from "react-icons/md";
import { TbSettingsCog } from "react-icons/tb";
import { RiBankLine } from "react-icons/ri";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import { AiOutlineProfile } from "react-icons/ai";
import { MdPayment } from "react-icons/md";
import { TbArrowsExchange } from "react-icons/tb";
import { VscGraph } from "react-icons/vsc";


const IconResource = ({ type, size = 20, ...props }) => {
  switch (type) {
    case "loader":
      return <Loader color="var(--primary)" size={14} />;
    case "info":
      return <FaInfoCircle color="var(--primary)" size={14} />;
    case "dashboard":
      return <AiOutlineDashboard size={size} {...props} />;
    case "car":
      return <IoCarSportOutline size={size} {...props} />;
    case "booking":
      return <AiOutlineAccountBook size={size} {...props} />;
    case "package":
      return <FiPackage size={size} {...props} />;
    case "manage":
      return <MdManageAccounts size={size} {...props} />;
    case "search":
      return <IoSearchOutline size={size} {...props} />;
    case "bag":
      return <IoBagAddOutline size={size} {...props} />;
    case "users":
      return <FiUsers size={size} {...props} />;
    case "report":
      return <HiOutlineDocumentReport size={size} {...props} />;
    case "help":
      return <IoIosHelpCircleOutline size={size} {...props} />;
    case "rupee":
      return <PiCurrencyInrBold size={size} {...props} />;
    case "doubletick":
      return <RiCheckDoubleFill size={size} {...props} />;
    case "plus":
      return <GoPlus size={size} {...props} />;
    case "cross":
      return <RxCross2 size={size} {...props} />;
    case "add":
      return <IoAddCircleOutline size={size} {...props} />;
    case "edit":
      return <MdEditNote size={25} {...props} />;
    case "trash":
      return <GoTrash size={size} {...props} />;
    case "delete":
      return <RxCrossCircled color="red" size={size} {...props} />;
    case "google":
      return <FcGoogle size={size} {...props} />;
    case "rightArrow":
      return <FaLongArrowAltRight size={size} {...props} />;
    case "roundPlus":
      return <FiPlusCircle size={size} {...props} />;
    case "envelop":
      return <FaRegEnvelopeOpen size={size} {...props} />;
    case "lock":
      return <IoLockClosedOutline size={size} {...props} />;
    case "unlock":
      return <IoLockOpenOutline size={size} {...props} />;
    case "back":
      return <IoArrowBackOutline size={size} {...props} />;
    case "backangle":
      return <FaAngleLeft size={size} {...props} />;
    case "menu":
      return <IoMdMenu size={size} {...props} />;
    case "adduser":
      return <AiOutlineUserAdd size={size} {...props} />;
    case "insta":
      return <FaInstagram size={size} {...props} />;
    case "facebook":
      return <FaFacebookSquare size={size} {...props} />;
    case "arrowDown":
      return <FaArrowAltCircleDown size={size} {...props} />;
    case "calendar":
      return <CiCalendarDate size={size} {...props} />;
    case "list":
      return <FaListOl size={size} {...props} />;
    case "buy":
      return <BiPurchaseTag size={size} {...props} />;
    case "shortcut":
      return <MdOutlineShortcut size={size} {...props} />;
    case "dropdown":
      return <IoIosArrowDropdown size={size} {...props} />;
    case "filter":
      return <IoFilterSharp size={size} {...props} />;
    case "copy":
      return <MdOutlineContentCopy size={size} {...props} />;
    case "percent":
      return <AiOutlinePercentage size={size} {...props} />;
    case "business":
      return <MdBusinessCenter size={size} {...props} />;
    case "settings":
      return <TbSettingsCog size={size} {...props} />;
    case "convert":
      return <MdPublishedWithChanges size={size} {...props} />;
    case "bank":
      return <RiBankLine size={size} {...props} />;
    case "invoice":
      return <LiaFileInvoiceSolid size={size} {...props} />;
    case "proforma":
      return <AiOutlineProfile size={size} {...props} />;
    case "payment":
      return <MdPayment size={size} {...props} />;
    case "exchange":
      return <TbArrowsExchange size={size} {...props} />;
    case "leads":
      return <VscGraph size={size} {...props} />;
    case "slash":
      return <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={20}
        height={"auto"}
        fill={"black"}
        {...props}
      >
        <g>
          <line x1="2" y1="23" x2="13" y2="5" stroke="currentColor" strokeWidth="2" />
          <line x1="20" y1="15" x2="14" y2="15" stroke="currentColor" strokeWidth="2" />
        </g>
      </svg>;
    case "logout":
      return <IoIosLogOut size={size} {...props} />;
    default:
      return null;
  }
};

export default IconResource;
