export const OPEN = 0;
export const CONFIRMED = 1;
export const ACCEPTED = 2;
export const STARTED = 3;
export const COMPLETED = 4;
export const CANCELLED = 5;
export const REACHED = 6;
export const TRIP_ENDED = 7;
export const ACTIVE = "ACTIVE";
export const INACTIVE = "INACTIVE";
export const CLIENT_ADMIN = "client_admin";
export const CLIENT_USER = "client_user";
export const DISPATCHER_USER = "dispatcher"
export const DRIVER = "Driver"
export const SUPER_ADMIN = "super_admin"
export const DISPATCHER = "DISPATCHER"
export const SAADIGADDI = "SAADIGADDI"