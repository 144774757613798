import * as apiProvider from './provider';
import * as urlConstants from '../Constants/urlConstants';

export function login(reqData) {
  return apiProvider.post(urlConstants.LOGIN, reqData);
}

export function register(reqData) {
  return apiProvider.post(urlConstants.REGISTER, reqData);
}

export function resetPassword(reqData) {
  return apiProvider.post(urlConstants.RESET_PASSWORD, reqData)
}

export function changePassword(reqData) {
  return apiProvider.post(urlConstants.CHANGE_PASSWORD, reqData)
}

export function forgotPassword(reqData) {
  return apiProvider.post(urlConstants.FORGOT_PASSWORD, reqData)
}

export function verifyOtp(reqData) {
  return apiProvider.post(urlConstants.VERIFY_OTP, reqData)
}

export function resendOtp(reqData) {
  return apiProvider.post(urlConstants.RESEND_OTP, reqData)
}

export function getProfile() {
  return apiProvider.getAll(urlConstants.GET_PROFILE)
}

export function updateProfile(reqData) {
  return apiProvider.post(urlConstants.UPDATE_PROFILE, reqData)
}

export function masterData() {
  return apiProvider.getAll(urlConstants.MASTER_DATA);
}

export function systemData() {
  return apiProvider.getAll(urlConstants.SYSTEM_DATA);
}

export async function getUserList(query, reqData) {
  return await apiProvider.post(urlConstants.GET_USERS_LIST + query, reqData);
}

export async function getClientList(query, reqData) {
  return await apiProvider.post(urlConstants.GET_CLIENT_LIST + query, reqData);
}

export async function getBookingList(query, reqData) {
  return await apiProvider.post(urlConstants.GET_BOOKING_LIST + query, reqData);
}

export async function getBookingDetails(id) {
  return await apiProvider.getAll(urlConstants.GET_BOOKING_DETAILS + id);
}

export async function getPackageList(query, reqData) {
  return await apiProvider.post(urlConstants.GET_PACKAGE_LIST + query, reqData);
}

export async function getVehicleList(query, reqData) {
  return await apiProvider.post(urlConstants.GET_VEHICLE_LIST + query, reqData);
}

export function upsertUser(reqData) {
  return apiProvider.post(urlConstants.UPSERT_USER, reqData);
}

export function upsertClient(reqData) {
  return apiProvider.post(urlConstants.UPSERT_CLIENT, reqData);
}

export function upsertBooking(reqData) {
  return apiProvider.post(urlConstants.UPSERT_BOOKING, reqData);
}

export function upsertBookingDetails(reqData) {
  return apiProvider.post(urlConstants.UPSERT_BOOKING_DETAILS, reqData);
}

export function upsertPaymentDetails(reqData) {
  return apiProvider.post(urlConstants.UPSERT_PAYMENT_DETAILS, reqData);
}

export function upsertPackage(reqData) {
  return apiProvider.post(urlConstants.UPSERT_PACKAGE, reqData);
}

export function upsertVehicle(reqData) {
  return apiProvider.post(urlConstants.UPSERT_VEHICLE, reqData);
}

export function cancelBooking(id, reqData) {
  return apiProvider.post(urlConstants.CANCEL_BOOKING + id, reqData);
}

export function deletePackage(id) {
  return apiProvider.deleteAPI(urlConstants.DELETE_PACKAGE + id);
}

export function deleteVehicle(id) {
  return apiProvider.deleteAPI(urlConstants.DELETE_VEHICLE + id);
}

export function deleteUser(id) {
  return apiProvider.deleteAPI(urlConstants.DELETE_USER + id);
}

export function getBusinessList(query) {
  return apiProvider.post(urlConstants.GET_BUSINESS_LIST + query);
}


export function getBusinessDetails(id) {
  return apiProvider.get(urlConstants.GET_BUSINESS_LIST + '/', id);
}

export function upsertBusiness(reqData) {
  return apiProvider.post(urlConstants.UPSERT_BUSINESS, reqData);
}

export function getBankList(query, reqData) {
  return apiProvider.post(urlConstants.GET_BANK_LIST + query, reqData);
}

export function upsertBank(reqData) {
  return apiProvider.post(urlConstants.UPSERT_BANK, reqData);
}

export function deleteBank(id) {
  return apiProvider.deleteAPI(urlConstants.DELETE_BANK + id);
}

export function getInvoiceList(query, reqData) {
  return apiProvider.post(urlConstants.GET_INVOICE_LIST + query, reqData);
}

export function getInvoiceById(id) {
  return apiProvider.get(urlConstants.GET_INVOICE_LIST + '/', id);
}

export function upsertInvoice(reqData) {
  return apiProvider.post(urlConstants.UPSERT_INVOICE, reqData);
}

export function changeInvoiceStatus(id, reqData) {
  return apiProvider.post(urlConstants.CHANGE_INVOICE_STATUS + id, reqData);
}

export function upsertInvoicePayment(reqData) {
  return apiProvider.post(urlConstants.UPSERT_INVOICE_PAYMENT, reqData);
}
export function changePackagesStatus(id, reqData) {
  return apiProvider.post(urlConstants.CHANGE_PACKAGES_STATUS + id, reqData);
}
export function changeVehicleStatus(id, reqData) {
  return apiProvider.post(urlConstants.CHANGE_VEHICLE_STATUS + id, reqData);
}
export function changeUserStatus(id, reqData) {
  return apiProvider.post(urlConstants.CHANGE_USER_STATUS + id, reqData);
}

export function deleteInvoice(id) {
  return apiProvider.deleteAPI(urlConstants.DELETE_INVOICE + id);
}

export function deleteClient(id) {
  return apiProvider.deleteAPI(urlConstants.DELETE_CLIENT + id);
}

export function getProformaList(query, reqData) {
  return apiProvider.post(urlConstants.GET_PROFORMA_LIST + query, reqData);
}

export function getProformaInvoice(id) {
  return apiProvider.get(urlConstants.GET_PROFORMA_INVOICE, id);
}

export function upsertProforma(reqData) {
  return apiProvider.post(urlConstants.UPSERT_PROFORMA, reqData);
}

export function deleteProformaInvoice(id) {
  return apiProvider.deleteAPI(urlConstants.DELETE_PROFORMA_INVOICE + id);
}

export function getDashboardData(reqData) {
  return apiProvider.post(urlConstants.GET_DASHBOARD_DATA, reqData);
}

export function getBookingPlaces(reqData) {
  return apiProvider.post(urlConstants.BOOKING_PLACES, reqData);
}

export function getInvoicePDF(id) {
  return apiProvider.getAll(urlConstants.BOOKING_INVOICE + id);
}
// Dispatcher api's
export async function getDispatcherList(query, reqData) {
  return await apiProvider.post(urlConstants.GET_DISPATCHER_LIST + query, reqData);
}
export function upsertDispatcher(reqData) {
  return apiProvider.post(urlConstants.UPSERT_DISPATCHER, reqData);
}

export async function getDispatcherDetails(id) {
  return await apiProvider.getAll(urlConstants.GET_DISPATCHER_DETAILS + id);
}
export async function getDispatcherBooking(id, reqData) {
  return await apiProvider.getAll(urlConstants.GET_DISPATCHER_BOOKINGS + id + reqData);
}
export async function getPaymentList(query, reqData) {
  return await apiProvider.getAll(urlConstants.GET_PAYMENT_LIST + `/${reqData}` + query);
}
export async function upsertPayment(query, reqData) {
  return await apiProvider.post(urlConstants.UPSERT_PAYMENT + `/${query}`, reqData);
}
export function deletePayment(id) {
  return apiProvider.deleteAPI(urlConstants.DELETE_PAYMENT + id);
}
