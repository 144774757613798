import React from "react";
import { Popover } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function CustomTooltip({ type = 'tooltip', text, children, placement, button, ...props }) {
  let overlay;
  let trigger;
  let rootClose;

  switch (type) {
    case 'tooltip':
      overlay = text ? <Tooltip {...props}><div dangerouslySetInnerHTML={{ __html: text }} /></Tooltip> : null;
      trigger = ['hover', 'focus']; // Set trigger for tooltip to hover and focus
      rootClose = false; // Tooltip doesn't need rootClose
      break;
    case 'popover':
      overlay = <Popover {...props}>{button}</Popover>;
      trigger = ['click']; // Set trigger for popover to click only
      rootClose = true; // Close popover when clicking outside
      break;
    default:
      overlay = null;
      trigger = []; // No trigger
      rootClose = false;
      break;
  }

  return (
    <>
      {overlay ? (
        <OverlayTrigger
          placement={placement}
          delay={{ show: 250, hide: 400 }}
          overlay={overlay}
          trigger={trigger} // Use the trigger defined above
          rootClose={rootClose} // Close popover on outside click
        >
          <div tabIndex="0">{children}</div>
        </OverlayTrigger>
      ) : (
        <div>{children}</div>
      )}
    </>
  );
}

export default CustomTooltip;